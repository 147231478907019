<template>
  <base-section id="faq">
    <base-section-heading :title="$t('faq.title')"> </base-section-heading>
    <v-container>
      <v-row justify="center">
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
            style="text-align: center;"
          >
            <v-row justify="center" no-gutters>
              <v-col cols="12">
                <span style="font-weight: bold;">
                  {{ $t(card.titleCode) }}
                </span>
              </v-col>
              <v-col cols="12">
                <span style="color: rgba(0, 0, 0, 0.54);">
                  {{ $t(card.textCode) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
    </v-container>
  </base-section>
</template>

<style>

</style>

<script>
export default {
  name: "faq",
  data: () => ({
    cards: [
      {
        title: "How long is the implementation process?",
        titleCode: "faq.question1",
        textCode: "faq.question1Answer",
        text: "You can get set up with your Tentifly space within the same day. We only have to identify you as our user and make mostly automatic setting up before we ship your Tentifly space code."
      },
      {
        title: "How does the implementation process look like?",
        titleCode: "faq.question2",
        textCode: "faq.question2Answer",
        text: "After you have been granted access to Tentifly, everything depends on your business case. Minimum you need is setting up service or products and creating a form. Once that is done youre good to go. Everything else is mainly customization and setting up apps or webpage."
      },
      {
        title: "How does Tentifly suit for my growing business?",
        titleCode: "faq.question3",
        textCode: "faq.question3Answer",
        text: "Tentifly comes with diverse forms and solid workflows. With diverse functionality you can choose what you use and you have always full capabilities of our software at your hand. Tentifly comes with no limitations to reasonable number of users, forms, orders etc. Even Tentifly grows as times go on and will support additional functionalities involving marketing, automatic leads, analytics, more automation etc."
      },
      {
        title: "Do I have to have a business to use Tentifly?",
        titleCode: "faq.question4",
        textCode: "faq.question4Answer",
        text: "No. Everyone can use Tentifly for their needs. We will bill everyone equally."
      },
      {
        title: "How long will I learn the software?",
        titleCode: "faq.question5",
        textCode: "faq.question5Answer",
        text: "Our software aims to be intuitive and with short, but effective workflows. In ordinary case, you should be able to understand the software within minutes of test-running. We also have guides for modules and more complex cases."
      },
      {
        title: "What preparations should I make before setting up Tentifly for my business?",
        titleCode: "faq.question6",
        textCode: "faq.question6Answer",
        text: "In general, none. Tentifly comes as full-suited package service and only thing you need to consider is integrating Tentifly into your business processes. Usually it's just throwing away your notebook."
      },
      {
        title: "What happens if I dont have network connectivity or it fails on me?",
        titleCode: "faq.question7",
        textCode: "faq.question7Answer",
        text: "Tentifly supports some functionality even in offline mode. For this mode to be active you don't have to do anything as it kicks in itself and stores information offline for you to confirm changes once you get back online."
      },
      {
        title: "What happens if I forget to pay for subscription on time?",
        titleCode: "faq.question8",
        textCode: "faq.question8Answer",
        text: "We don't punish for humanly mistakes. If you forget to pay on time your access will not get locked out immediately giving you enough time to earn and pay."
      },
      {
        title: "I have existing data and/or service in use. How can I transfer that information to Tentifly?",
        titleCode: "faq.question9",
        textCode: "faq.question9Answer",
        text: "For such cases contact us and we will use our automated tools to import any data you want to see in Tentifly."
      },
      {
        title: "Software doesn't quite suit my needs and lacks functionality. Is there any help?",
        titleCode: "faq.question10",
        textCode: "faq.question10Answer",
        text: "We have a backlog of functionalities to support. The more we have your kind of users, the more we will put our effort to supplying functionality that caters your needs. You can always contact us directly with such matters. If your request is reasonable, we might even provide the functionality immediately!"
      },
      {
        title: "I want to digitalize all my information. Can Tentifly help me with that?",
        titleCode: "faq.question11",
        textCode: "faq.question11Answer",
        text: "Tentifly doesn't import notebooks, but we do help you keep information digital and in order. Once you start using Tentifly you should never need any physical papers except invoices. These you will still have to print :)."
      },
      {
        title: "I have a website. Can I use it along with Tentifly?",
        titleCode: "faq.question12",
        textCode: "faq.question12Answer",
        text: "You can use Tentifly in your phone, share phone app / form link with your customers and ofcource yes, add Tentifly even in your page. We support iframe and Tentifly plugin solutions. You can customize the plugin for your needs and we have installation guides available for most cases you can have."
      },
      {
        title: "How can I get and send notification via Tentifly?",
        titleCode: "faq.question13",
        textCode: "faq.question13Answer",
        text: "Any automatic notifications can be configured per form. With changes to orders, upon saving you will be prompted with notification confirmation or just use dedicated button. Tentifly supports e-mails and will soon support SMS functionality."
      },
      {
        title: "I am having trouble with setting up or using Tentifly. Can I get help?",
        titleCode: "faq.question14",
        textCode: "faq.question14Answer",
        text: "All our customers can get help via email or scheduled support. Depending on case and severity of problem we might help you directly or direct you to one of our how-to guides. If guide doesn't help then we will lend our hand."
      }
    ]
  })
};
</script>
